import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { ICampaignBannerProps } from './model';

import './CampaignBanner.scss';

const CampaignBanner: FC<ICampaignBannerProps> = ({ title, image }) => {
  const bannerImage = image?.[0]?.properties?.image;

  return (
    <div className="campaign-banner">
      <DangerouslySetInnerHtml html={title} className="campaign-banner__title" />
      {bannerImage?.childImageSharp ? (
        <div className="campaign-banner__image">
          <GatsbyImage alt={bannerImage?.imageAlt} fluid={bannerImage?.childImageSharp.fluid} />
        </div>
      ) : null}
    </div>
  );
};

export default CampaignBanner;
