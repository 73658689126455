import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';

import { IBannerInfoItemProps } from './model';

import './BannerInfoItem.scss';

const BannerInfoItem: FC<IBannerInfoItemProps> = ({
  title,
  description,
  disclaimer,
  brandLogo,
  image,
  sectionBackground,
  className,
}) => {
  const imageSlogan = image?.[0].properties;
  const imageBrand = brandLogo?.[0].properties;

  return (
    <div
      data-testid="BannerInfoItem"
      className={classnames('banner-info-item', className, {
        [`banner-info-item--bg-${sectionBackground?.label}`]: sectionBackground?.label,
      })}
    >
      {title ? <DangerouslySetInnerHtml html={title} className="banner-info-item__title" /> : null}
      {description ? (
        <DangerouslySetInnerHtml html={description} className="banner-info-item__description" />
      ) : null}
      {imageSlogan ? (
        <GatsbyImage
          alt={imageSlogan?.image?.imageAlt}
          fluid={imageSlogan?.image?.childImageSharp.fluid}
          wrapperClasses="banner-info-item__image"
        />
      ) : null}
      {imageBrand ? (
        <GatsbyImage
          alt={imageBrand?.imageAltLabel}
          fluid={imageBrand?.image?.childImageSharp.fluid}
          wrapperClasses="banner-info-item__image-brand"
        />
      ) : null}
      {disclaimer ? (
        <DangerouslySetInnerHtml html={disclaimer} className="banner-info-item__disclaimer" />
      ) : null}
    </div>
  );
};

export default BannerInfoItem;

export const query = graphql`
  fragment FragmentBannerInfoItem on TBannerInfoItem {
    properties {
      title
      description
      disclaimer
      image {
        ...FragmentImageWithAlt
      }
      brandLogo {
        ...FragmentImageWithAlt
      }
      sectionBackground {
        label
      }
    }
  }
`;
