/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { FC, useEffect, useRef, useState } from 'react';

import { IMediaBannerComponentProps } from 'components/MediaBanner/models';
import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import Video from 'components/Video';
import Button from 'common/Button';
import IconCustom from 'common/IconCustom';

import 'components/MediaBanner/MediaBanner.scss';

import useImageResizeOnScroll from 'hooks/useImageResizeOnScroll';

const MediaBanner: FC<IMediaBannerComponentProps> = ({
  mainImage,
  innerImage,
  videoLinkDescription,
  videoUrl,
  themeColor,
  themeSwitcher,
  description,
  title,
  setPageThemeColor,
}) => {
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const mainImageEl = useRef(null);
  const innerImageEl = useRef(null);

  useEffect(() => {
    setPageThemeColor(themeColor?.label);
  }, []);

  useImageResizeOnScroll({ mainImageEl, innerImageEl });

  const switchTheme = (e) => {
    e.stopPropagation();

    e.target.checked ? setPageThemeColor(null) : setPageThemeColor(themeColor?.label);
  };

  return (
    <div className="media-banner" data-testid="media-banner">
      <div className="media-banner__container">
        <div className="media-banner__column">
          <div className="media-banner__text-container">
            <label id="theme-switcher" className="theme-switcher">
              <input onClick={switchTheme} type="checkbox" />
              <div className="switch">
                <span>{themeSwitcher}</span>
              </div>
            </label>
            <DangerouslySetInnerHtml className="media-banner__title" html={title} />
            {description && (
              <DangerouslySetInnerHtml className="media-banner__description" html={description} />
            )}
            <Button
              ariaLabel={videoLinkDescription}
              className="media-banner__video-button"
              handler={() => setIsVideo(!isVideo)}
              btnColorVariant="white"
            >
              <IconCustom icon="play3" />
              {videoLinkDescription}
            </Button>
            {isVideo && (
              <div className="media-banner__video-wrapper">
                <Button className="video__button-close" handler={() => setIsVideo(!isVideo)}>
                  <IconCustom className="button-close__icon" icon="close" />
                </Button>
                <Video videoURL={videoUrl} className="media-banner__video" />
              </div>
            )}
          </div>
        </div>
        <div className="media-banner__column media-banner__column--image">
          <div className="media-banner__image-container">
            {innerImage && (
              <div ref={innerImageEl} className="media-banner__inner-image">
                <GatsbyImage
                  fluid={innerImage[0]?.properties?.image?.childImageSharp?.fluid}
                  alt={mainImage[0]?.properties?.imageAltLabel}
                  objectFit="cover"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {mainImage && (
        <div ref={mainImageEl} className="media-banner__main-image">
          <GatsbyImage
            fluid={mainImage[0]?.properties?.image?.childImageSharp?.fluid}
            alt={mainImage[0]?.properties?.imageAltLabel}
            objectFit="cover"
          />
        </div>
      )}
    </div>
  );
};

export default MediaBanner;
