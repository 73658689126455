import React, { FC } from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs, Pagination } from 'swiper';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import breakpoints from 'components/RelatedArticles/constants';

import EpisodeCard from './EpisodeCard';

import { IEpisodesSectionProps } from './model';

import useScreenRecognition from '../../hooks/useScreenRecognition';

import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation/navigation.scss';
import './EpisodeSection.scss';

SwiperCore.use([Navigation, Pagination, Thumbs]);

const EpisodesSection: FC<IEpisodesSectionProps> = ({
  episodes,
  relatedEpisodesTitle,
  relatedEpisodesSubtitle,
  episodesCount = 3,
  displayInRow = false,
  hidePlayIcon = false,
  ctaTitle,
  ctaAria,
  readAllLink,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <section
      className={classnames('episodes-section', {
        'episodes-section--row': displayInRow,
      })}
    >
      <div className="episodes-section__container">
        {relatedEpisodesTitle ? (
          <h2 className="episodes-section__title">{relatedEpisodesTitle}</h2>
        ) : null}

        {relatedEpisodesSubtitle ? (
          <DangerouslySetInnerHtml
            className="episodes-section__subtitle"
            html={relatedEpisodesSubtitle}
          />
        ) : null}

        {isMobile && displayInRow ? (
          <Swiper slidesPerView={1} pagination={{ clickable: true }} breakpoints={breakpoints}>
            {episodes?.nodes?.map(
              ({ properties: { title, subtitle, description, poster }, url }) => (
                <SwiperSlide key={url}>
                  <div className="episodes-section__card">
                    <EpisodeCard
                      url={url}
                      poster={poster?.gatsbyImage.childImageSharp.fluid}
                      posterAlt={poster.altText}
                      title={title}
                      subtitle={subtitle}
                      description={description}
                      ctaTitle={ctaTitle}
                      ctaAria={ctaAria}
                      hidePlayIcon={hidePlayIcon}
                      displayInRow={displayInRow}
                    />
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        ) : (
          <ul
            className={classnames('episodes-section__list', {
              'two-columns': episodesCount === 2,
              'three-columns': episodesCount >= 3,
            })}
          >
            {episodes?.nodes
              ?.slice(0, episodesCount)
              ?.map(({ properties: { title, subtitle, description, poster }, url }) => (
                <li className="episodes-section__card" key={url}>
                  <EpisodeCard
                    url={url}
                    poster={poster?.gatsbyImage.childImageSharp.fluid}
                    posterAlt={poster.altText}
                    title={title}
                    subtitle={subtitle}
                    description={description}
                    ctaTitle={ctaTitle}
                    ctaAria={ctaAria}
                    hidePlayIcon={hidePlayIcon}
                    displayInRow={displayInRow}
                  />
                </li>
              ))}
          </ul>
        )}

        {displayInRow && readAllLink?.[0]?.name ? (
          <Button
            ariaLabel={readAllLink[0].name}
            link={readAllLink?.[0]?.url}
            className="episodes-section__read-all"
          >
            {readAllLink[0].name}
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export default EpisodesSection;
