import { graphql } from 'gatsby';
import React, { FC } from 'react';
import GatsbyImage from 'common/GatsbyImage';
import BannerInfoItem from 'components/BannerInfoItem/BannerInfoItem';
import { IThreeColumnBannerProps } from './model';

import './ThreeColumnBanner.scss';

const ThreeColumnBanner: FC<IThreeColumnBannerProps> = ({
  contentCenter,
  imageRight,
  imageLeft,
}) => {
  const imageOnLeft = imageLeft?.[0].properties;
  const imageOnRight = imageRight?.[0].properties;

  return (
    <section className="three-column-banner" data-testid="three-column-banner">
      {imageOnLeft ? (
        <GatsbyImage
          alt={imageOnLeft?.image?.imageAlt}
          fluid={imageOnLeft?.image?.childImageSharp.fluid}
          wrapperClasses="three-column-banner__image-left"
          objectFit="cover"
        />
      ) : null}
      {contentCenter?.[0]?.properties ? (
        <BannerInfoItem
          {...contentCenter?.[0].properties}
          className="three-column-banner__center"
        />
      ) : null}
      {imageOnRight ? (
        <GatsbyImage
          alt={imageOnRight?.image?.imageAlt}
          fluid={imageOnRight?.image?.childImageSharp.fluid}
          wrapperClasses="three-column-banner__image-right"
          objectFit="cover"
        />
      ) : null}
    </section>
  );
};

export default ThreeColumnBanner;

export const query = graphql`
  fragment FragmentThreeColumnBanner on TThreeColumnBanner {
    properties {
      contentCenter {
        ...FragmentBannerInfoItem
      }
      imageLeft {
        ...FragmentImageWithAlt
      }
      imageRight {
        ...FragmentImageWithAlt
      }
    }
  }
`;
