import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';

import { ISurveyResultsSectionProps } from './model';

import './SurveyResultsSection.scss';

const SurveyResultsSection: FC<ISurveyResultsSectionProps> = ({ items }) => {
  return (
    <section className="survey-results">
      {items?.map(
        ({
          properties: {
            title,
            resultOut,
            resultDivider,
            resultOf,
            resultDescription,
            description,
            image,
            sectionBackground: { label } = {},
          },
        }) => {
          const { childImageSharp, imageAlt } = image?.[0]?.properties?.image || {};

          return (
            <div
              className={classnames('survey-results__item', {
                [`survey-results__item--bg-${label}`]: label,
              })}
            >
              <div className="survey-results__item-text-wrapper">
                <div className="survey-results__item-text">
                  <DangerouslySetInnerHtml html={title} className="survey-results__item-title" />
                  <div className="survey-results__brief">
                    <div className="survey-results__brief-numbers">
                      <p className="survey-results__brief-number-out">{resultOut}</p>
                      <span className="survey-results__brief-divider">{resultDivider}</span>
                      <p className="survey-results__brief-number-of">{resultOf}</p>
                    </div>
                    <DangerouslySetInnerHtml
                      html={resultDescription}
                      className="survey-results__brief-description"
                    />
                  </div>
                  <DangerouslySetInnerHtml
                    html={description}
                    className="survey-results__item-description"
                  />
                </div>
              </div>
              {childImageSharp ? (
                <div className="survey-results__item-image">
                  <GatsbyImage alt={imageAlt} fluid={childImageSharp?.fluid} />
                </div>
              ) : null}
            </div>
          );
        }
      )}
    </section>
  );
};

export const query = graphql`
  fragment FragmentSurveyItem on TSurveyItem {
    properties {
      title
      resultOut
      resultDivider
      resultOf
      resultDescription
      description
      sectionBackground {
        label
      }
      image {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default SurveyResultsSection;
