import React, { FC } from 'react';
import classnames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';

import { IIntroSectionProps } from './model';

import './IntroSection.scss';

const IntroSection: FC<IIntroSectionProps> = ({ title, description, link, sectionTheme }) => {
  const { name, url } = link?.[0] || {};

  return (
    <div
      className={classnames('intro-section', {
        [`intro-section--${sectionTheme?.[0]}`]: sectionTheme?.[0],
      })}
    >
      <h2 className="intro-section__title">{title}</h2>
      <DangerouslySetInnerHtml className="intro-section__description" html={description} />
      {name ? (
        <Button
          ariaLabel={name}
          link={url}
          className="intro-section__link"
          btnColorVariant="always-dark"
        >
          {name}
        </Button>
      ) : null}
    </div>
  );
};

export default IntroSection;
