import React, { FC } from 'react';
import { Link } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import IconCustom from 'common/IconCustom';
import Button from 'common/Button';

import { IEpisodeCardProps } from './model';

const EpisodeCard: FC<IEpisodeCardProps> = ({
  url,
  poster,
  posterAlt,
  hidePlayIcon,
  title,
  subtitle,
  description,
  ctaTitle,
  ctaAria,
  displayInRow,
}) => (
  <>
    {displayInRow ? (
      <>
        <div className="episodes-section__card-top">
          <Link to={url} className="episodes-section__card-link">
            <div className="episodes-section__card-image">
              <GatsbyImage fluid={poster} alt={posterAlt} />
              {!hidePlayIcon ? <IconCustom icon="play3" /> : null}
            </div>
          </Link>
          <div className="episodes-section__card-text">
            <p className="episodes-section__card-title">{title}</p>
            <DangerouslySetInnerHtml
              className="episodes-section__card-description episodes-section__card-description--desktop"
              html={description}
            />
            <p className="episodes-section__card-subtitle">{subtitle}</p>
          </div>
        </div>
        <DangerouslySetInnerHtml
          className="episodes-section__card-description episodes-section__card-description--mobile"
          html={description}
        />
        {ctaTitle && ctaAria ? (
          <Button
            classes="episodes-section__cta"
            btnColorVariant="outline-dark"
            ariaLabel={ctaAria}
            link={url}
          >
            {ctaTitle}
          </Button>
        ) : null}
      </>
    ) : (
      <Link to={url} className="episodes-section__card-link">
        <div className="episodes-section__card-top">
          <div className="episodes-section__card-image">
            <GatsbyImage fluid={poster} alt={posterAlt} />
            {!hidePlayIcon ? <IconCustom icon="play3" /> : null}
          </div>
          <div className="episodes-section__card-text">
            <p className="episodes-section__card-title">{title}</p>
            <p className="episodes-section__card-subtitle">{subtitle}</p>
            <DangerouslySetInnerHtml
              className="episodes-section__card-description episodes-section__card-description--desktop"
              html={description}
            />
          </div>
        </div>
        <DangerouslySetInnerHtml
          className="episodes-section__card-description episodes-section__card-description--mobile"
          html={description}
        />
      </Link>
    )}
  </>
);

export default EpisodeCard;
