import React, { FC, useState } from 'react';

import ArticleCardsList from 'components/ArticleCardsList';

import { IArticleCardsListWrapper } from './models';

const ArticleCardsListWrapper: FC<IArticleCardsListWrapper> = ({
  data,
  readMoreText,
  ariaLabel,
  nextAria,
  prevAria,
  paginationText,
  quantityItemsMobile,
  quantityItemsDesktop,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <div className="article-cards-wrapper">
      <ArticleCardsList
        handleActiveListPage={setCurrentPage}
        currentPage={currentPage}
        data={data}
        readMoreText={readMoreText}
        ariaLabel={ariaLabel}
        paginationText={paginationText}
        nextAria={nextAria}
        prevAria={prevAria}
        isWithoutUrlParams
        quantityItemsDesktop={quantityItemsDesktop}
        quantityItemsMobile={quantityItemsMobile}
      />
    </div>
  );
};

export default ArticleCardsListWrapper;
