import { useEffect } from 'react';
import { useImageResizeOnScrollHook, IObserverOptions } from './model';

const useImageResizeOnScroll: useImageResizeOnScrollHook = ({ mainImageEl, innerImageEl }) => {
  useEffect(() => {
    const innerImageImg = innerImageEl?.current?.querySelector('picture img');
    const mainImageImg = mainImageEl?.current?.querySelector('picture img');

    if (!innerImageImg && !mainImageImg) return;

    const options: IObserverOptions = {
      root: null,
      rootMargin: '0px',
      threshold: [],
    };

    let num = 0;
    while (num <= 1) {
      options.threshold.push(num);
      num += 0.01;
    }

    const observerInnerImg = new IntersectionObserver(([entry]) => {
      (entry.target as HTMLImageElement).style.transform = `scale(${
        1.5 - entry.intersectionRatio / 2
      })`;
    }, options);

    const observerMainImg = new IntersectionObserver(([entry]) => {
      (entry.target as HTMLImageElement).style.transform = `scale(${
        1.1 - entry.intersectionRatio / 10
      })`;
    }, options);

    observerMainImg.observe(mainImageImg);
    observerInnerImg.observe(innerImageImg);
  }, []);
};

export default useImageResizeOnScroll;
