import React, { FC, useRef } from 'react';
import classNames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';

import { IImageBannerComponentProps } from 'components/ImageBanner/models';

import 'components/ImageBanner/ImageBanner.scss';
import useImageResizeOnScroll from 'hooks/useImageResizeOnScroll';

const ImageBanner: FC<IImageBannerComponentProps> = ({
  mainImage,
  innerImage,
  textBlockImage,
  themeColor,
  description,
  title,
}) => {
  const mainImageEl = useRef(null);
  const innerImageEl = useRef(null);

  useImageResizeOnScroll({ mainImageEl, innerImageEl });

  return (
    <div
      className={classNames('image-banner', {
        [`image-banner--${themeColor?.label}`]: themeColor?.label,
      })}
      data-testid="image-banner"
    >
      <div className="image-banner__container">
        <div className="image-banner__column">
          <div className="image-banner__text-container">
            <DangerouslySetInnerHtml className="image-banner__title" html={title} />
            {description && (
              <DangerouslySetInnerHtml className="image-banner__description" html={description} />
            )}
            {textBlockImage && (
              <div ref={innerImageEl} className="image-banner__text-image">
                <GatsbyImage
                  fluid={textBlockImage[0]?.properties?.image?.childImageSharp?.fluid}
                  alt={mainImage[0]?.properties?.imageAltLabel}
                  objectFit="contain"
                />
              </div>
            )}
          </div>
        </div>

        <div className="image-banner__column image-banner__column--image">
          <div className="image-banner__image-container">
            {innerImage && (
              <div ref={innerImageEl} className="image-banner__inner-image">
                <GatsbyImage
                  fluid={innerImage[0]?.properties?.image?.childImageSharp?.fluid}
                  alt={mainImage[0]?.properties?.imageAltLabel}
                  objectFit="cover"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {mainImage && (
        <div ref={mainImageEl} className="image-banner__main-image">
          <GatsbyImage
            fluid={mainImage[0]?.properties?.image?.childImageSharp?.fluid}
            alt={mainImage[0]?.properties?.imageAltLabel}
            objectFit="cover"
          />
        </div>
      )}
    </div>
  );
};

export default ImageBanner;
