import React, { FC } from 'react';

import ControlledCarousel from 'common/ControlledCarousel';
import GatsbyImage from 'common/GatsbyImage';

import { IFactsSection } from './model';
import responsiveObj from './constants';
import './FactsSection.scss';

const FactsSection: FC<IFactsSection> = ({ facts, image }) => {
  const sectionImage = image?.[0]?.properties?.image;

  return (
    <section className="facts-section">
      <ControlledCarousel
        showDots
        dotsOutside
        classes="facts-section__slider"
        responsiveObj={responsiveObj}
      >
        {facts.map(({ properties: { title, description } }) => (
          <div className="facts-section__slider-item" key={title}>
            <h2 className="facts-section__slider-title">{title}</h2>
            <p className="facts-section__slider-description">{description}</p>
          </div>
        ))}
      </ControlledCarousel>

      {sectionImage?.childImageSharp ? (
        <GatsbyImage alt={sectionImage?.imageAlt} fluid={sectionImage?.childImageSharp.fluid} />
      ) : null}
    </section>
  );
};

export default FactsSection;
