import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import ControlledCarousel from 'common/ControlledCarousel';
import GatsbyImage from 'common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Video from 'components/Video';
import Button from 'common/Button';
import IconCustom from 'common/IconCustom/IconCustom';

import { IVideoBannerCarousel } from './model';
import './VideoBannerCarousel.scss';

import responsiveObj from './constants';

const VideoBannerCarousel: FC<IVideoBannerCarousel> = ({ videoBannerItems, sectionBackground }) => {
  const { isDesktop } = useScreenRecognition();
  const [activeVideo, setActiveVideo] = useState(null);

  const handlePlayVideo = (videoURL) => {
    setActiveVideo(videoURL);
  };

  const handleCloseVideo = () => {
    setActiveVideo(null);
  };

  return (
    <section
      className={classnames('video-banner-carousel', {
        [`video-banner-carousel--bg-${sectionBackground?.label}`]: sectionBackground?.label,
      })}
    >
      <ControlledCarousel
        showDots={!isDesktop}
        showNavigators={!!isDesktop}
        navigatorsOutside={!!isDesktop}
        dotsOutside
        classes="video-banner-carousel__slider"
        responsiveObj={responsiveObj}
        prevIcon="arrow-left-thin"
        nextIcon="arrow-left-thin"
        onChangeAction={handleCloseVideo}
      >
        {videoBannerItems.map(
          ({
            properties: {
              videoImage,
              title,
              subtitle,
              text,
              hostedVideo,
              ctaAria,
              sectionBackground: videoItemBg,
              sectionTheme,
            },
          }) => (
            <div
              className={classnames('video-banner-carousel__slider-item', {
                [`video-banner-carousel__slider-item--bg-${videoItemBg?.label}`]: videoItemBg?.label,
                [`video-banner-carousel__slider-item--position-${sectionTheme?.[0]}`]: sectionTheme?.[0],
              })}
              key={title}
            >
              <div className="video-banner-carousel__slider-item--content">
                {title ? (
                  <DangerouslySetInnerHtml
                    html={title}
                    className="video-banner-carousel__slider-item--title"
                  />
                ) : null}
                {subtitle ? (
                  <DangerouslySetInnerHtml
                    html={subtitle}
                    className="video-banner-carousel__slider-item--subtitle"
                  />
                ) : null}
                {text ? (
                  <DangerouslySetInnerHtml
                    html={text}
                    className="video-banner-carousel__slider-item--text"
                  />
                ) : null}
              </div>

              <div className="video-banner-carousel__video-wrapper">
                {!activeVideo || activeVideo !== hostedVideo ? (
                  <>
                    {ctaAria ? (
                      <Button
                        ariaLabel={ctaAria}
                        handler={() => handlePlayVideo(hostedVideo)}
                        className="video-banner-carousel__cta"
                      >
                        <IconCustom icon="play3" />
                      </Button>
                    ) : null}
                    {videoImage?.[0].properties ? (
                      <GatsbyImage
                        alt={videoImage?.[0].properties.image?.imageAlt}
                        fluid={videoImage?.[0].properties.image?.childImageSharp.fluid}
                        wrapperClasses="video-banner-carousel__bg-image"
                        objectFit="cover"
                      />
                    ) : null}
                  </>
                ) : null}

                {activeVideo === hostedVideo && (
                  <div className="video-banner-carousel__video-screen">
                    <Button
                      className="video__button-close"
                      ariaLabel={ctaAria}
                      handler={handleCloseVideo}
                    >
                      <IconCustom className="button-close__icon" icon="close" />
                    </Button>
                    <Video key={hostedVideo} videoURL={hostedVideo} autoplay />
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </ControlledCarousel>
    </section>
  );
};

export default VideoBannerCarousel;

export const query = graphql`
  fragment FragmentTVideoBannerCarouselItem on TVideoBannerCarouselItem {
    properties {
      hostedVideo
      videoImage {
        ...FragmentImageWithAlt
      }
      ctaAria
      title
      subtitle
      text
      sectionBackground {
        label
      }
      sectionTheme
    }
  }
`;
