import React, { useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import { Container, Layout } from 'layout';
import BodyRenderer from 'common/BodyRenderer';
import RelatedArticles from 'components/RelatedArticles';
import VideoPosterSection from 'components/VideoPosterSection';
import ManifestSection from 'components/ManifestSection';
import FactsSection from 'components/FactsSection';
import PromoSection from 'components/PromoSection';
import ProductFeaturesImageSection from 'components/ProductFeaturesImageSection';
import RelatedProducts from 'components/RelatedProducts';
import FaqSection from 'components/FaqSection';
import MediaBanner from 'components/MediaBanner';
import ImageBanner from 'components/ImageBanner';
import IntroSection from 'components/IntroSection';
import EpisodesSection from 'components/EpisodesSection';
import CampaignBanner from 'components/CampaignBanner';
import SurveyResultsSection from 'components/SurveyResultsSection';
import ThreeColumnBanner from 'components/ThreeColumnBanner/ThreeColumnBanner';
import TwoColumnBannerCarousel from 'components/TwoColumnBannerCarousel';
import VideoBannerCarousel from 'components/VideoBannerCarousel';
import ArticleCardsListWrapper from 'components/ArticleCardsListWrapper';

import breakpoints from 'components/RelatedArticles/constants';

import './CampainPage.scss';

const bodyStructure = {
  'Related articles': (
    {
      properties: {
        relatedArticles,
        relatedArticlesTitle,
        relatedArticlesSubtitle,
        relatedArticlesReadMore,
        articleLinks,
        buttonHoverStyle,
        titleColor,
        readAllLink,
        showTags,
        slidesPerViewMobile,
      },
    },
    keyId
  ) => {
    const cx = classnames({
      [`related-articles__button-hover--${buttonHoverStyle?.[0]}`]: buttonHoverStyle?.[0],
      [`related-articles__title-color--${titleColor?.[0]}`]: titleColor?.[0],
    });

    return (
      <Container key={keyId} className={cx}>
        <RelatedArticles
          breakpoints={breakpoints}
          data={
            articleLinks
              ? relatedArticles.nodes.filter(({ url }) => articleLinks.includes(url))
              : relatedArticles.nodes
          }
          title={relatedArticlesTitle}
          subtitle={relatedArticlesSubtitle}
          readMoreText={relatedArticlesReadMore}
          ariaLabel={relatedArticlesReadMore}
          articlesCount={4}
          readAllLink={readAllLink}
          showTags={showTags}
          slidesPerViewMobile={slidesPerViewMobile}
        />
      </Container>
    );
  },
  'Media Banner': (
    {
      properties: {
        mainImage,
        innerImage,
        videoLinkDescription,
        hostedVideo,
        cmsVideo,
        themeColor,
        themeSwitcher,
        description,
        title,
        setPageThemeColor,
      },
    },
    keyId
  ) => (
    <MediaBanner
      key={keyId}
      mainImage={mainImage}
      innerImage={innerImage}
      videoLinkDescription={videoLinkDescription}
      videoUrl={hostedVideo || cmsVideo?.fallbackUrl}
      themeColor={themeColor}
      themeSwitcher={themeSwitcher}
      description={description}
      title={title}
      setPageThemeColor={setPageThemeColor}
    />
  ),
  'Image Banner': (
    { properties: { mainImage, innerImage, textBlockImage, themeColor, description, title } },
    keyId
  ) => (
    <ImageBanner
      key={keyId}
      mainImage={mainImage}
      innerImage={innerImage}
      textBlockImage={textBlockImage}
      themeColor={themeColor}
      description={description}
      title={title}
    />
  ),
  'Video Poster Section': ({
    properties: {
      title,
      ctaTitle,
      ctaAria,
      ctaAriaClose,
      ctaAnchor,
      sectionAnchor,
      hostedVideo,
      cmsVideo,
      image,
      sectionTheme,
      sectionBackground,
    },
    keyId,
  }) => (
    <VideoPosterSection
      key={keyId}
      title={title}
      ctaTitle={ctaTitle}
      ctaAria={ctaAria}
      ctaAriaClose={ctaAriaClose}
      ctaAnchor={ctaAnchor}
      sectionAnchor={sectionAnchor}
      videoLink={hostedVideo || cmsVideo?.fallbackUrl}
      image={image}
      sectionTheme={sectionTheme}
      sectionBackground={sectionBackground}
    />
  ),
  'Manifest Section': (
    {
      properties: {
        title,
        description,
        slogan,
        ctaTitle,
        ctaHref,
        ctaAria,
        sectionBackground,
        text,
      },
    },
    keyId
  ) => (
    <ManifestSection
      key={keyId}
      title={title}
      description={description}
      slogan={slogan}
      text={text}
      ctaAria={ctaAria}
      ctaTitle={ctaTitle}
      ctaHref={ctaHref}
      sectionBackground={sectionBackground}
    />
  ),
  'Facts Section': ({ properties: { facts, image } }, keyId) => (
    <FactsSection key={keyId} facts={facts} image={image} />
  ),
  'Promo Section': (
    { properties: { title, description, logo, productImage, sectionBackgroundImage } },
    keyId
  ) => (
    <PromoSection
      key={keyId}
      title={title}
      description={description}
      logo={logo}
      productImage={productImage}
      sectionBackground={sectionBackgroundImage}
    />
  ),
  'Product Features Image Section': ({ properties: { desktopImage, mobileImage } }, keyId) => (
    <ProductFeaturesImageSection
      key={keyId}
      desktopImage={desktopImage}
      mobileImage={mobileImage}
    />
  ),
  'Related Products': (
    {
      properties: {
        mainProduct,
        relatedProducts,
        relatedProductsTitle,
        relatedProductsDescription,
        relatedProductsBadge,
        relatedProductsCardCtaButton,
        relatedProductsCentered,
        productLinks,
        sectionBackground,
      },
    },
    keyId
  ) => {
    const cx = classnames('related-products-section', {
      [`related-products-section--bg-${sectionBackground?.label}`]: sectionBackground?.label,
    });

    return (
      <section className={cx}>
        <RelatedProducts
          key={keyId}
          relatedProductsTitle={relatedProductsTitle}
          relatedProductsCardCtaButton={relatedProductsCardCtaButton}
          relatedProductsDescription={relatedProductsDescription}
          relatedProductsCentered={relatedProductsCentered}
          relatedProductsBadge={relatedProductsBadge}
          mainProduct={mainProduct}
          relatedProducts={
            productLinks
              ? { nodes: relatedProducts.nodes.filter(({ url }) => productLinks.includes(url)) }
              : relatedProducts
          }
        />
      </section>
    );
  },
  'FAQ Section': ({ properties: { title, accordionItems, notes } }, keyId) => (
    <FaqSection key={keyId} title={title} accordionItems={accordionItems} notes={notes} />
  ),
  'Intro Section': ({ properties: { title, description, link, sectionTheme } }, keyId) => (
    <IntroSection
      key={keyId}
      title={title}
      description={description}
      link={link}
      sectionTheme={sectionTheme}
    />
  ),
  'Related episodes': (
    {
      properties: {
        episodes,
        episodesCount,
        relatedEpisodesTitle,
        relatedEpisodesSubtitle,
        displayInRow,
        hidePlayIcon,
        ctaTitle,
        ctaAria,
        readAllLink,
      },
    },
    keyId
  ) => (
    <EpisodesSection
      key={keyId}
      relatedEpisodesTitle={relatedEpisodesTitle}
      relatedEpisodesSubtitle={relatedEpisodesSubtitle}
      episodes={episodes}
      episodesCount={episodesCount}
      displayInRow={displayInRow}
      hidePlayIcon={hidePlayIcon}
      ctaTitle={ctaTitle}
      ctaAria={ctaAria}
      readAllLink={readAllLink}
    />
  ),
  'Campaign Banner': ({ properties: { title, image } }, keyId) => (
    <CampaignBanner key={keyId} title={title} image={image} />
  ),
  'Survey Results': ({ properties: { items } }, keyId) => (
    <SurveyResultsSection key={keyId} items={items} />
  ),
  'Three Column Banner': ({ properties: { imageLeft, imageRight, contentCenter } }, keyId) => (
    <ThreeColumnBanner
      key={keyId}
      imageLeft={imageLeft}
      imageRight={imageRight}
      contentCenter={contentCenter}
    />
  ),
  'Two Column Banner Carousel': ({ properties: { bannerItems } }, keyId) => (
    <TwoColumnBannerCarousel bannerItems={bannerItems} key={keyId} />
  ),
  'Video Banner Carousel': ({ properties: { videoBannerItems, sectionBackground } }, keyId) => (
    <VideoBannerCarousel
      key={keyId}
      videoBannerItems={videoBannerItems}
      sectionBackground={sectionBackground}
    />
  ),
  'Articles List': (
    {
      properties: {
        articlesListItems,
        ariaLabel,
        readMoreText,
        nextAria,
        prevAria,
        paginationText,
        quantityItemsMobile,
        quantityItemsDesktop,
      },
    },
    keyId
  ) => (
    <div className="container article-cards-wrapper">
      <ArticleCardsListWrapper
        data={articlesListItems}
        readMoreText={readMoreText}
        ariaLabel={ariaLabel}
        paginationText={paginationText}
        nextAria={nextAria}
        prevAria={prevAria}
        quantityItemsMobile={quantityItemsMobile}
        quantityItemsDesktop={quantityItemsDesktop}
        key={keyId}
      />
    </div>
  ),
};

const CampaignPage = ({
  data: {
    page: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          defaultCompositions,
          pageTheme,
          body,
        },
      ],
    },
    relatedArticles,
    relatedProducts,
    mainProduct,
    episodes,
  },
}) => {
  const [pageThemeColor, setPageThemeColor] = useState<string | null>(null);
  const cx = classnames('campaign-page', {
    [`campaign-page--${pageThemeColor}`]: pageThemeColor,
    [pageTheme?.join(' ')]: pageTheme?.length,
  });
  const {
    commonSettings: {
      blocks: [
        {
          properties: { nextAria, prevAria },
        },
      ],
    },
  } = defaultCompositions;

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
      }}
    >
      <div className={cx}>
        {body?.length > 0 ? (
          <BodyRenderer
            bodyData={body}
            bodyStructure={bodyStructure}
            bodyItemProps={{
              relatedArticles,
              relatedProducts,
              mainProduct,
              episodes,
              setPageThemeColor,
              nextAria,
              prevAria,
            }}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CampaignPage(
    $url: String = ""
    $relatedArticlesLinks: [String]
    $relatedProductsLinks: [String]
    $relatedEpisodesLinks: [String]
    $lang: String
    $mainProduct: String
  ) {
    page: allUmbracoCampaign(filter: { url: { eq: $url }, lang: { eq: $lang } }) {
      nodes {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        pageTheme
        body {
          properties {
            articlesList {
              url
            }
            articleLinks
            badgeText
            description
            innerImage {
              ...FragmentImageWithAlt
            }
            mainImage {
              ...FragmentImageWithAlt
            }
            relatedArticlesTitle
            relatedArticlesSubtitle
            relatedArticlesReadMore
            slidesPerViewMobile
            readAllLink {
              name
              url
            }
            textBlockImage {
              ...FragmentImageWithAlt
            }
            themeColor {
              label
              value
            }
            themeSwitcher
            title
            videoLinkDescription
            hostedVideo
            cmsVideo {
              fallbackUrl
            }
            ctaAria
            ctaAriaClose
            ctaTitle
            ctaAnchor
            ctaHref
            link {
              name
              url
            }
            sectionAnchor
            sectionTheme
            episodesCount
            facts {
              properties {
                description
                title
              }
            }
            image {
              ...FragmentImageWithAlt
            }
            productImage {
              ...FragmentImageWithAlt
            }
            logo {
              ...FragmentImageWithAlt
            }
            sectionBackgroundImage {
              ...FragmentImageWithAlt
            }
            desktopImage {
              ...FragmentImageWithAlt
            }
            mobileImage {
              ...FragmentImageWithAlt
            }
            relatedProductsTitle
            relatedProductsCardCtaButton
            relatedProductsDescription
            relatedProductsCentered
            showTags
            slogan
            accordionItems {
              properties {
                answer
                question
              }
            }
            notes
            relatedProductsBadge
            sectionBackground {
              label
            }
            buttonHoverStyle
            titleColor
            items {
              ...FragmentSurveyItem
            }
            text
            relatedEpisodesTitle
            relatedEpisodesSubtitle
            displayInRow
            hidePlayIcon
            imageLeft {
              ...FragmentImageWithAlt
            }
            imageRight {
              ...FragmentImageWithAlt
            }
            contentCenter {
              ...FragmentBannerInfoItem
            }
            bannerItems {
              ...FragmentTwoColumnBannerItem
            }
            videoBannerItems {
              ...FragmentTVideoBannerCarouselItem
            }
            articlesListItems {
              id
              url
              properties {
                title
                articleHeroBannerMainImageAltText
                tags {
                  name
                  url {
                    url
                    name
                  }
                }
                articleBody {
                  structure
                  properties {
                    articleIntroductionText
                  }
                }
                localArticleImage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 95) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            readMoreText
            ariaLabel
            paginationText {
              properties {
                nextPage
                prevPage
              }
            }
            quantityItemsDesktop
            quantityItemsMobile
          }
          structure
        }
        defaultCompositions {
          ...DefaultCompositionsFragment
        }
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
          amountCondoms
          productFeatureDescription
        }
      }
    }
    mainProduct: allUmbracoProducts(filter: { url: { eq: $mainProduct } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
          amountCondoms
          productFeatureDescription
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $relatedArticlesLinks } }) {
      nodes {
        id
        title
        properties {
          title
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          tags {
            name
            url {
              url
              name
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    episodes: allUmbracoEpisodes(
      filter: { lang: { eq: $lang }, url: { in: $relatedEpisodesLinks } }
    ) {
      nodes {
        url
        properties {
          ...EpisodeFragment
        }
      }
    }
  }
`;

export default CampaignPage;
