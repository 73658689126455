import React, { FC } from 'react';
import { graphql } from 'gatsby';

import ControlledCarousel from 'common/ControlledCarousel';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import BannerInfoItem from 'components/BannerInfoItem/BannerInfoItem';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { ITwoColumnBannerCarousel } from './model';
import './TwoColumnBannerCarousel.scss';

import responsiveObj from './constants';

const TwoColumnBannerCarousel: FC<ITwoColumnBannerCarousel> = ({ bannerItems }) => {
  const { isDesktop } = useScreenRecognition();

  return (
    <section className="two-column-banner-carousel">
      <ControlledCarousel
        showDots={!isDesktop}
        showNavigators={!!isDesktop}
        infinite
        dotsOutside
        classes="two-column-banner-carousel__slider"
        responsiveObj={responsiveObj}
        prevIcon="arrow-left-thin"
        nextIcon="arrow-left-thin"
      >
        {bannerItems.map(({ properties: { image, backgroundImage, content, link } }) => (
          <div
            className="two-column-banner-carousel__slider-item"
            key={content?.[0].properties.title}
          >
            <div className="two-column-banner-carousel__slider-item--left">
              {backgroundImage?.[0].properties ? (
                <GatsbyImage
                  alt={backgroundImage?.[0].properties.image?.imageAlt}
                  fluid={backgroundImage?.[0].properties.image?.childImageSharp.fluid}
                  wrapperClasses="two-column-banner-carousel__bg-image"
                  objectFit="cover"
                />
              ) : null}
              {image?.[0]?.properties && link?.[0]?.url ? (
                <Button link={link?.[0].url} ariaLabel={link?.[0]?.name}>
                  <GatsbyImage
                    alt={image?.[0].properties.image?.imageAlt}
                    fluid={image?.[0].properties.image?.childImageSharp.fluid}
                    wrapperClasses="two-column-banner-carousel__image"
                    objectFit="cover"
                  />
                </Button>
              ) : null}
            </div>
            <div className="two-column-banner-carousel__slider-item--right">
              {content?.[0]?.properties ? (
                <BannerInfoItem
                  {...content?.[0].properties}
                  className="two-column-banner-carousel__content"
                />
              ) : null}
            </div>
          </div>
        ))}
      </ControlledCarousel>
    </section>
  );
};

export default TwoColumnBannerCarousel;

export const query = graphql`
  fragment FragmentTwoColumnBannerItem on TTwoColumnBannerItem {
    properties {
      image {
        ...FragmentImageWithAlt
      }
      backgroundImage {
        ...FragmentImageWithAlt
      }
      content {
        ...FragmentBannerInfoItem
      }
      link {
        url
        name
      }
    }
  }
`;
