import React, { FC } from 'react';

import GatsbyImage from 'common/GatsbyImage';

import { IProductFeaturesImageSection } from './model';
import './ProductFeaturesImageSection.scss';

const ProductFeaturesImageSection: FC<IProductFeaturesImageSection> = ({
  desktopImage,
  mobileImage,
}) => {
  return (
    <section className="product-feature-section">
      {mobileImage?.[0]?.properties?.image?.childImageSharp ? (
        <GatsbyImage
          alt={mobileImage?.[0]?.properties?.image?.imageAlt}
          fluid={mobileImage?.[0]?.properties?.image?.childImageSharp.fluid}
          wrapperClasses="product-feature-section__mobile"
        />
      ) : null}

      {desktopImage?.[0]?.properties?.image?.childImageSharp ? (
        <GatsbyImage
          alt={desktopImage?.[0]?.properties?.image?.imageAlt}
          fluid={desktopImage?.[0]?.properties?.image?.childImageSharp.fluid}
          wrapperClasses="product-feature-section__desktop"
        />
      ) : null}
    </section>
  );
};

export default ProductFeaturesImageSection;
