import React, { FC } from 'react';
import classnames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';

import { IManifestSection } from './model';

import './ManifestSection.scss';

const ManifestSection: FC<Partial<IManifestSection>> = ({
  title,
  description,
  slogan,
  text,
  ctaAria,
  ctaTitle,
  ctaHref,
  sectionBackground,
}) => {
  return (
    <section
      className={classnames('manifest-section', {
        [`manifest-section--bg-${sectionBackground?.label}`]: sectionBackground?.label,
      })}
    >
      <div className="manifest-section__content">
        {title ? (
          <DangerouslySetInnerHtml className="manifest-section__title" html={title} />
        ) : null}
        {description ? (
          <DangerouslySetInnerHtml className="manifest-section__description" html={description} />
        ) : null}
        {slogan ? (
          <DangerouslySetInnerHtml className="manifest-section__slogan" html={slogan} />
        ) : null}
        {text ? <DangerouslySetInnerHtml className="manifest-section__text" html={text} /> : null}
        {ctaTitle ? (
          <Button className="manifest-section__cta" link={ctaHref} ariaLabel={ctaAria}>
            {ctaTitle}
          </Button>
        ) : null}
      </div>
    </section>
  );
};

export default ManifestSection;
