import React, { FC } from 'react';

import ControledAccordion from 'components/ControledAccordion';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';

import { IFaqSection } from './model';
import './FaqSection.scss';

const FaqSection: FC<IFaqSection> = ({ title, notes, accordionItems }) => {
  return (
    <section className="faq-section">
      <div className="faq-section__content">
        <h2 className="faq-section__title">{title}</h2>
        <ControledAccordion className="faq-section__accordion" items={accordionItems} />
        <DangerouslySetInnerHtml className="faq-section__notes" html={notes} />
      </div>
    </section>
  );
};

export default FaqSection;
