import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { IPromoSection } from './model';
import './PromoSection.scss';

const PromoSection: FC<IPromoSection> = ({
  title,
  description,
  logo,
  productImage,
  sectionBackground,
}) => {
  return (
    <section className="promo-section">
      <div className="promo-section__product-image">
        {productImage?.[0]?.properties?.image?.childImageSharp ? (
          <GatsbyImage
            alt={productImage?.[0]?.properties?.image?.imageAlt}
            fluid={productImage?.[0]?.properties?.image?.childImageSharp.fluid}
          />
        ) : null}
      </div>

      <div className="promo-section__text-container">
        <div className="promo-section__text">
          <h2 className="promo-section__text-title">{title}</h2>
          <DangerouslySetInnerHtml className="promo-section__text-description" html={description} />
        </div>
      </div>

      <div className="promo-section__logo-container">
        <div className="promo-section__logo-background">
          {sectionBackground?.[0]?.properties?.image?.childImageSharp ? (
            <GatsbyImage
              alt={sectionBackground?.[0]?.properties?.image?.imageAlt}
              fluid={sectionBackground?.[0]?.properties?.image?.childImageSharp.fluid}
            />
          ) : null}
        </div>

        <div className="promo-section__logo">
          {logo?.[0]?.properties?.image?.childImageSharp ? (
            <GatsbyImage
              alt={logo?.[0]?.properties?.image?.imageAlt}
              fluid={logo?.[0]?.properties?.image?.childImageSharp.fluid}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default PromoSection;
