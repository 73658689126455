import React, { FC, useState } from 'react';
import classnames from 'classnames';

import GatsbyImage from 'common/GatsbyImage';
import Video from 'components/Video';
import Button from 'common/Button';
import IconCustom from 'common/IconCustom';
import { DangerouslySetInnerHtml } from 'layout';

import { IVideoPosterSection } from './model';
import './VideoPosterSection.scss';

const VideoPosterSection: FC<IVideoPosterSection> = ({
  title,
  ctaTitle,
  ctaAria,
  ctaAriaClose,
  ctaAnchor,
  sectionAnchor,
  videoLink,
  image,
  sectionTheme,
  sectionBackground,
}) => {
  const [playVideo, setPlayVideo] = useState(false);
  const handlePlayVideo = () => setPlayVideo(!playVideo);

  const sectionImage = image?.[0]?.properties?.image;
  const sectionId = sectionAnchor ? { id: sectionAnchor } : {};

  const cx = classnames('video-poster-section', {
    'video-poster-section--player': videoLink,
    [sectionTheme?.join(' ') as string]: sectionTheme?.length,
    [`video-poster-section--bg-${sectionBackground?.label}`]: sectionBackground?.label,
  });

  return (
    <section {...sectionId} className={cx}>
      <div className="video-poster-section__container">
        <div className="video-poster-section__content">
          <DangerouslySetInnerHtml className="video-poster-section__title" html={title} />
          {ctaTitle ? (
            <Button
              ariaLabel={ctaAria}
              link={ctaAnchor}
              handler={handlePlayVideo}
              className="video-poster-section__cta"
            >
              <IconCustom icon="play3" />
              {ctaTitle}
            </Button>
          ) : null}
        </div>

        {sectionImage?.childImageSharp ? (
          <GatsbyImage alt={sectionImage?.imageAlt} fluid={sectionImage?.childImageSharp.fluid} />
        ) : null}

        {playVideo && (
          <div className="video-poster-section__video-wrapper">
            <Button
              className="video__button-close"
              ariaLabel={ctaAriaClose}
              handler={() => setPlayVideo(!playVideo)}
            >
              <IconCustom className="button-close__icon" icon="close" />
            </Button>
            <Video videoURL={videoLink} autoplay />
          </div>
        )}
      </div>
    </section>
  );
};

export default VideoPosterSection;
